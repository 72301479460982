<template>
  <div>
    <TopBanner
      @iconClick='iconClick'
    />
    <div class="mt-10px px-1vw">
      <a-row :gutter="10">
        <a-col :span="8">
          <div class="broker-card">
            <p class="card-title">Customers</p>
            <p class="card-value">{{customer_count}}</p>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="broker-card">
            <p class="card-title">Volumn</p>
            <p class="card-value">$ {{transaction_volum_str}}</p>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="broker-card">
            <p class="card-title">Commission</p>
            <p class="card-value">$ {{balance_data.balance}}</p>
          </div>
        </a-col>
      </a-row>
    </div>
    <a-divider style="margin: 14px 0 0;" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TopBanner from '@/components/User/TopBanner'

export default {
  props: {
    username: {
      type: String,
      default: 'Andy'
    }
  },
  components: {
    TopBanner
  },
  computed: {
    ...mapState('login', ['user']),
    ...mapState('broker', ['customer_count', 'transaction_volum', 'commission_current', 'transaction_volum_str', 'balance_data'])
  },
  methods: {
    iconClick () {
      this.$emit('iconClick')
    }
  }
}

</script>

<style scoped>
.top-banner-container {
  box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
}
.broker-card {
  text-align: center;
  padding: 10px;
  height: 70px;
  border-radius: 10px;
  -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  background-color: #fdfdfd;
}
.card-title {
  font-weight: 500;
}
.card-value {
  font-size: 16px;
  font-weight: 500;
}
</style>
